<template>
  <div class="monitor-detail">
    <we-card :title="`監視 / ${living.eventName}`">
      <div class="md-layout">
        <div class="md-layout-item md-size-50 md-xsmall-size-100">
          <div class="md-layout">
            <div class="md-layout-item md-size-100" style="">
              {{ currentTimestamp }}
            </div>
            <div class="md-layout-item md-size-50">
              <div class="nowrap" style="margin-top: 30px">
                <span class="nickname">{{ living.liverName }}</span>
              </div>
              <div style="height: 20px;"></div>
              <div class="stream" id="liver_stream" style="margin-top: 1px">
                <img
                  v-if="waitingLiver"
                  src="@/assets/img/monitor-waiting.svg"
                />
              </div>
            </div>
            <div class="md-layout-item md-size-50">
              <div style="height: 30px"></div>
              <div class="nowrap" style="margin-top: 0px">
                <span class="nickname">{{ currUserName }}</span>
                <span style="display: inline-flex">
                  <md-icon style="color: rgba(224, 81, 147, 100)"
                    >person</md-icon
                  >
                  <span style="font-size: 11px">{{ currUserIndex }}</span>
                </span>
              </div>
              <div style="height: 20px;">
                {{ description }}
              </div>
              <div class="stream" id="fan_stream">
                <img
                  v-if="currStream === null"
                  src="@/assets/img/monitor-waiting.svg"
                />
                <div class="dropdown-menu-body" v-if="living">
                  <dropdown-menu
                    :overlay="false"
                    direction="center"
                    ref="isOpenLogDropdown"
                  >
                    <!-- <we-button
                      size="small"
                      slot="trigger"
                      class="dropdown-menu-content-btn"
                      >報告</we-button
                    > -->
                    <div slot="trigger" class="dropdown-menu-content-btn">
                      <md-icon class="dropdown-menu-icon"
                        >priority_high</md-icon
                      >
                    </div>
                    <div slot="header" class="dropdown-menu-content-header">
                      事象を選択してください
                    </div>
                    <div slot="body" class="dropdown-menu-content-body">
                      <div
                        class="dropdown-menu-content-item"
                        v-for="(item, index) in living.logOptions"
                        :key="index"
                        @click="addMonitorLog(item.logOptionId, item.title)"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </dropdown-menu>
                </div>
              </div>
            </div>
          </div>
          <div
            class="md-layout md-alignment-top-center"
            style="margin-top: 20px"
          >
            <we-button v-if="living.paused" size="small" @click="resume"
              >再開</we-button
            >
            <we-button v-else size="small" @click="pause">一時停止</we-button>
            <we-button
              size="small"
              outline="red"
              @click="ban(getCurrUserId(), currUserName)"
              >BAN</we-button
            >
            <we-button
              size="small"
              outline="red"
              :to="{ name: 'monitor', query: { section: section, page: page } }"
              >戻る</we-button
            >
          </div>
        </div>
        <div class="md-layout-item md-size-50 md-xsmall-size-100">
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <div class="nowrap" style="margin-top: 0px">
                次のユーザー
              </div>
              <div class="nowrap" style="margin-top: 30px">
                <span class="nickname">{{ nextUserName }}</span>
                <span style="display: inline-flex">
                  <md-icon style="color: rgba(224, 81, 147, 100)"
                    >person</md-icon
                  >
                  <span style="font-size: 11px">{{ nextUserIndex }}</span>
                </span>
              </div>
              <div style="height: 20px;"></div>
              <div>
                <div class="stream" id="waiting_stream">
                  <img
                    v-if="nextStream === null"
                    src="@/assets/img/monitor-waiting.svg"
                  />
                </div>
              </div>
              <div
                class="md-layout md-alignment-top-center"
                style="margin-top: 20px"
              >
                <we-button
                  size="small"
                  outline="red"
                  @click="ban(getNextUserId(), nextUserName)"
                  >BAN</we-button
                >
              </div>
            </div>
            <div class="md-layout-item md-size-50">
              <div class="nowrap" style="margin-top: 0px">
                順番待ちリスト
              </div>
              <div style="height: 20px;"></div>
              <div class="waiting-list">
                <md-table style="margin-top: 0px">
                  <md-table-row>
                    <md-table-head>アイコン</md-table-head>
                    <md-table-head>ニックネーム</md-table-head>
                  </md-table-row>
                  <template v-for="(item, key) in waitingList">
                    <md-table-row
                      :key="key"
                      v-if="showWaitingUserInList(item.owner.userId, key)"
                    >
                      <md-table-cell>
                        <md-avatar class="md-small">
                          <img
                            v-if="item.owner.avatar"
                            :src="item.owner.avatar"
                            alt="Avatar"
                          />
                          <img v-else src="@/assets/img/avatar.png" />
                        </md-avatar>
                      </md-table-cell>
                      <md-table-cell>{{ item.owner.nickName }}</md-table-cell>
                    </md-table-row>
                  </template>
                </md-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </we-card>
    <div>
      <md-dialog :md-active.sync="prompt" md-input-maxlength="200">
        <md-dialog-title
          ><md-icon style="color: red;">warning</md-icon
          >BAN原因</md-dialog-title
        >
        <div style="margin: 0 20px; max-width: 400px;">
          <div class="reason-radios">
            <md-radio
              v-for="(item, key) in reasonList"
              :key="key"
              v-model="reasonValue"
              :value="item.value"
              >{{ item.text }}</md-radio
            >
          </div>
          <md-field :class="messageClass" class="reason-textarea">
            <md-textarea v-model="reason" required></md-textarea>
            <span class="md-error">この項目は必須です。</span>
          </md-field>
        </div>
        <md-dialog-actions>
          <md-button class="md-primary" @click="addBannedReason"
            >確認</md-button
          >
        </md-dialog-actions>
      </md-dialog>
    </div>
  </div>
</template>
<script>
// import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js'
import TRTC from "trtc-js-sdk";
import { ajax, common, config, constant } from "../../utils";

export default {
  data() {
    return {
      living: { liverName: null, eventName: null, paused: false },
      client: "",
      waitingLiver: true, // ライバーの待ち合わせ画像を表示するのか
      fanList: [],
      user1_mute: false,
      user2_mute: true,
      userStreams: [],
      prompt: false, // BANのダイアログを表示するのか
      reason: null, // BANの理由
      bannedUser: null,
      pauseInterval: null,
      serverTimeInterval: null,
      reasonValue: null,
      reasonList: [
        { value: 1, text: "誹謗中傷" },
        { value: 2, text: "不審な行為" },
        { value: 3, text: "不適切な場所からの参加" },
        { value: 9, text: "その他" },
      ],
      serverTime: new Date(),
    };
  },
  watch: {
    serverTime: function() {
      this.showWaitingStream();
      this.getWaitingList();
    },
  },
  computed: {
    pk() {
      return this.$route.params.id;
    },
    waitingList() {
      return this.fanList.filter((i) => {
        if (i.bannedFlag) {
          return false;
        }
        if (i.startTime === null) {
          return true;
        } else if (
          new Date(i.startTime) > this.serverTime &&
          i.bannedFlag === false
        ) {
          return true;
        } else {
          return false;
        }
      });
    },
    messageClass() {
      return {
        "md-invalid": this.hasError,
      };
    },
    section() {
      return this.$route.query.section;
    },
    page() {
      return this.$route.query.page;
    },
    currentTimestamp() {
      return `${this.serverTime.toLocaleDateString()} ${this.serverTime.toLocaleTimeString()}`;
    },
    prevUserTicket() {
      const userTickets = this.fanList.filter(
        (i) => new Date(i.endTime) < this.serverTime && i.endTime !== null
      );
      return userTickets.length > 0
        ? userTickets[userTickets.length - 1]
        : null;
    },
    prevStream() {
      const streams = this.userStreams.filter(
        (i) => i.userId_ === this.getPrevUserId()
      );
      return streams.length > 0 ? streams[0] : null;
    },
    currUserTicket() {
      return this.fanList.find(
        (i) =>
          new Date(i.startTime) <= this.serverTime &&
          new Date(i.endTime) >= this.serverTime &&
          i.bannedFlag === false
      );
    },
    currUserId() {
      const userTicket = this.currUserTicket;
      return userTicket ? userTicket.owner.userId : null;
    },
    currUserName() {
      const userTicket = this.currUserTicket;
      return userTicket ? userTicket.owner.nickName : null;
    },
    currUserIndex() {
      if (this.fanList.length > 0) {
        const userTicket = this.currUserTicket;
        if (userTicket) {
          return (
            this.fanList.findIndex(
              (i) => i.userTicketId === userTicket.userTicketId
            ) + 1
          );
        }
      }
      return null;
    },
    currStream() {
      const streams = this.userStreams.filter(
        (i) => i.userId_ === this.getCurrUserId()
      );
      return streams.length > 0 ? streams[0] : null;
    },
    nextUserTicket() {
      return this.waitingList.length > 0 ? this.waitingList[0] : null;
    },
    nextUserId() {
      const userTicket = this.nextUserTicket;
      return userTicket ? userTicket.owner.userId : null;
    },
    nextUserName() {
      const userTicket = this.nextUserTicket;
      return userTicket ? userTicket.owner.nickName : null;
    },
    nextUserIndex() {
      if (this.fanList.length > 0) {
        const userTicket = this.nextUserTicket;
        if (userTicket) {
          return (
            this.fanList.findIndex(
              (i) => i.userTicketId === userTicket.userTicketId
            ) + 1
          );
        }
      }
      return null;
    },
    nextStream() {
      const streams = this.userStreams.filter(
        (i) => i.userId_ === this.getNextUserId()
      );
      return streams.length > 0 ? streams[0] : null;
    },
    description() {
      // 権利数
      if (this.currUserTicket) {
        const seconds = common.dateDiffSeconds(
          this.serverTime,
          this.currUserTicket.endTime
        );
        return `権利数：${this.currUserTicket.ticketCount}、残り${parseInt(
          seconds
        )}秒`;
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.getLivingDetail();
    this.calculateScreenSize();
    window.addEventListener("resize", this.calculateScreenSize);
  },
  beforeDestroy() {
    this.leaveRoom(this.client);
    if (this.pauseInterval) {
      clearInterval(this.pauseInterval);
    }
    if (this.serverTimeInterval) {
      clearInterval(this.serverTimeInterval);
    }
    window.removeEventListener("resize", this.calculateScreenSize);
  },
  methods: {
    addMonitorLog(id, title) {
      if (this.currUserTicket) {
        ajax
          .post(common.formatStr(config.api.living.addMonitorLog, this.pk), {
            userTicketId: this.currUserTicket.userTicketId,
            logOptionId: id,
            logOptionTitle: title,
          })
          .then(() => {
            this.$refs.isOpenLogDropdown.menu.isOpen = false;
            this.$toasted.success(constant.SUCCESS.SUCCESS);
          })
          .catch(() => {
            this.$toasted.error(constant.ERROR.UNEXCEPTED);
          });
      } else {
        this.$toasted.error(constant.ERROR.NO_CURRUSER);
      }
    },
    getServerTime() {
      const vm = this;
      ajax
        .get(config.api.living.serverTime)
        .then((data) => {
          vm.serverTime = new Date(data.now);
        })
        .catch(() => {
          vm.serverTime = new Date();
        });
    },
    getWaitingList() {
      if (!this.pk) {
        return;
      }
      const vm = this;
      ajax
        .get(common.formatStr(config.api.living.waitingList, this.pk))
        .then((data) => {
          if (data) {
            vm.fanList = data;
          } else {
            vm.fanList = [];
          }
        });
    },
    /**
     * 順番待ちリストのユーザーを表示するかどうか
     */
    showWaitingUserInList(userId, index) {
      if (this.nextUserId === null && index === 0) {
        // 次のユーザーは表示してない場合、1つ目の項目は表示しない。
        return false;
      } else if (userId === this.nextUserId) {
        // 放送中のユーザーは表示しない
        return false;
      } else {
        return true;
      }
    },
    getUserNo(userId) {
      if (userId && userId.indexOf("_") >= 0) {
        return parseInt(userId.split("_")[1]);
      } else if (userId) {
        return parseInt(userId);
      } else {
        return null;
      }
    },
    getPrevUserId() {
      const userTicket = this.prevUserTicket;
      if (userTicket) {
        return `user_${userTicket.owner.userId}`;
      }
      return null;
    },
    getCurrUserId() {
      const userTicket = this.currUserTicket;
      if (userTicket) {
        return `user_${userTicket.owner.userId}`;
      }
      return null;
    },
    getNextUserId() {
      const userTicket = this.nextUserTicket;
      if (userTicket) {
        return `user_${userTicket.owner.userId}`;
      } else {
        return null;
      }
    },
    getLivingDetail() {
      const vm = this;
      ajax
        .get(common.formatStr(config.api.living.detail, this.pk))
        .then((data) => {
          vm.living = data;
          vm.pauseInterval = setInterval(vm.isPaused, 1000);
          vm.serverTimeInterval = setInterval(vm.getServerTime, 1000);
          vm.getWaitingList();
          vm.createClient(data);
        })
        .catch((error) => {
          if (error.status === 404) {
            vm.$toasted.error(constant.ERROR.NOT_FOUND);
            vm.$router.push({ name: "monitor" });
          } else if (error.errors) {
            Object.keys(error.errors).map((key) => {
              vm.$toasted.error(error.errors[key]);
            });
          } else {
            vm.$toasted.error(constant.ERROR.UNEXCEPTED);
          }
        });
    },
    isPaused() {
      const vm = this;
      ajax
        .get(common.formatStr(config.api.living.paused, this.pk))
        .then((data) => {
          if (vm.living.paused !== data) {
            vm.$toasted.success(
              data ? constant.SUCCESS.PAUSE : constant.SUCCESS.RESUME
            );
            vm.living.paused = data;
          }
        });
    },
    createClient(data) {
      const sdkAppId = data.appId;
      const userId = data.userId;
      const userSig = data.userSig;
      this.client = TRTC.createClient({
        mode: "rtc",
        sdkAppId,
        userId,
        userSig,
      });
      this.handleEvents(this.client);
      this.joinRoom(this.client, parseInt(this.pk));
    },
    joinRoom(client, roomId) {
      client
        .join({ roomId })
        .catch((error) => {
          console.error("进房失败 " + error);
        })
        .then(() => {
          console.log("进房成功");
        });
    },
    handleEvents(client) {
      const vm = this;
      client.on("stream-added", (event) => {
        const remoteStream = event.stream;
        console.log("远端流增加: " + remoteStream.getId());
        client.subscribe(remoteStream);
      });
      client.on("stream-removed", (event) => {
        const remoteStream = event.stream;
        console.log("远端流减少: " + remoteStream.getId());
        if (vm.living.trtcLiverId === remoteStream.userId_) {
          vm.waitingLiver = true;
        } else {
          const streamIndex = vm.userStreams.findIndex(
            (i) => i.userId_ === remoteStream.userId_
          );
          if (streamIndex >= 0) {
            vm.userStreams.splice(streamIndex, 1);
          }
        }
        if (remoteStream.isPlaying()) {
          remoteStream.stop();
        }
        vm.getWaitingList();
      });
      client.on("stream-subscribed", (event) => {
        const remoteStream = event.stream;
        console.log("远端流订阅成功：" + remoteStream.getId());
        vm.$nextTick(() => {
          if (vm.living.trtcLiverId === remoteStream.userId_) {
            remoteStream.play("liver_stream");
            vm.waitingLiver = false;
          } else {
            vm.userStreams.push(remoteStream);
            vm.showWaitingStream();
            vm.getWaitingList();
          }
        });
      });
    },
    leaveRoom(client) {
      client
        .leave()
        .then(() => {
          console.log("退房成功");
          this.client = null;
        })
        .catch((error) => {
          console.error("退房失败 " + error);
        });
    },
    showWaitingStream() {
      let stream = this.currStream;
      if (
        stream !== null &&
        (stream.div_ === null || stream.div_.offsetParent.id !== "fan_stream")
      ) {
        if (stream.isPlaying()) {
          stream.stop();
        }
        stream.play("fan_stream");
        stream.unmuteAudio();
      }
      stream = this.nextStream;
      if (
        stream !== null &&
        (stream.div_ === null ||
          stream.div_.offsetParent.id !== "waiting_stream")
      ) {
        if (stream.isPlaying()) {
          stream.stop();
        }
        stream.play("waiting_stream");
        stream.muteAudio();
      }
      stream = this.prevStream;
      if (stream != null && stream.isPlaying()) {
        stream.stop();
        const streamIndex = this.userStreams.indexOf(stream);
        if (streamIndex >= 0) {
          this.userStreams.splice(streamIndex, 1);
        }
      }
    },
    showBanDialog(userId) {
      this.prompt = true;
      this.bannedUser = userId;
    },
    closeBanDialog() {
      this.prompt = false;
      this.bannedUser = null;
      this.reason = null;
      this.reasonValue = null;
    },
    ban(userId, userName) {
      if (userId === null) {
        alert("ユーザーはまだいません。");
        return;
      }
      if (confirm(common.formatStr(constant.CONFIRM.BAN_WITH_NAME, userName))) {
        const vm = this;
        common.loading();
        ajax
          .post(common.formatStr(config.api.living.ban, this.pk), {
            users: [userId],
          })
          .then(() => {
            vm.$toasted.success(constant.SUCCESS.BAN);
            vm.getWaitingList();
            vm.showBanDialog(userId);
          })
          .catch((error) => {
            if (error.errors) {
              Object.keys(error.errors).map((key) => {
                vm.$toasted.error(error.errors[key]);
              });
            } else {
              vm.$toasted.error(constant.ERROR.UNEXCEPTED);
            }
          })
          .finally(() => {
            common.loaded();
          });
      }
    },
    addBannedReason() {
      if (
        this.reasonValue === 9 &&
        (this.reason === null || this.reason === "")
      ) {
        this.$toasted.error(constant.ERROR.RQUIRE_BANNED_REASON);
        return;
      } else if (!this.reasonValue) {
        this.$toasted.error(constant.ERROR.RQUIRE_CHOOSE_REASON);
        return;
      }
      const vm = this;
      common.loading();
      ajax
        .post(common.formatStr(config.api.living.bannedReason, this.pk), {
          users: [vm.bannedUser],
          comment: vm.getBannedReason(),
        })
        .then(() => {
          vm.$toasted.success(constant.SUCCESS.BANNED_REASON);
          vm.closeBanDialog();
        })
        .catch((error) => {
          if (error.errors) {
            Object.keys(error.errors).map((key) => {
              vm.$toasted.error(error.errors[key]);
            });
          } else {
            vm.$toasted.error(constant.ERROR.UNEXCEPTED);
          }
        })
        .finally(() => {
          common.loaded();
        });
    },
    pause() {
      const userTicket = this.currUserTicket;
      if (userTicket) {
        // 対話終わるまで5秒以内は一時停止できません。
        const diffSeconds = common.dateDiffSeconds(
          this.serverTime,
          userTicket.endTime
        );
        if (diffSeconds <= 5 && diffSeconds >= 0) {
          this.$toasted.error(constant.ERROR.PAUSE_IN_FIVE);
          return;
        }
      }
      if (confirm(constant.CONFIRM.PAUSE)) {
        const vm = this;
        common.loading();
        ajax
          .post(common.formatStr(config.api.living.pause, this.pk))
          .then(() => {
            // vm.living.paused = true;
            // vm.$toasted.success(constant.SUCCESS.PAUSE);
          })
          .catch((error) => {
            if (error.errors) {
              Object.keys(error.errors).map((key) => {
                vm.$toasted.error(error.errors[key]);
              });
            } else {
              vm.$toasted.error(constant.ERROR.UNEXCEPTED);
            }
          })
          .finally(() => {
            common.loaded();
          });
      }
    },
    resume() {
      if (confirm(constant.CONFIRM.RESUME)) {
        const vm = this;
        common.loading();
        ajax
          .post(common.formatStr(config.api.living.resume, this.pk))
          .then(() => {
            // vm.living.paused = false;
            // vm.$toasted.success(constant.SUCCESS.RESUME);
          })
          .catch((error) => {
            if (error.errors) {
              Object.keys(error.errors).map((key) => {
                vm.$toasted.error(error.errors[key]);
              });
            } else {
              vm.$toasted.error(constant.ERROR.UNEXCEPTED);
            }
          })
          .finally(() => {
            common.loaded();
          });
      }
    },
    dismiss() {
      if (confirm(constant.CONFIRM.DISMISS)) {
        const vm = this;
        common.loading();
        ajax
          .post(common.formatStr(config.api.living.dismiss, this.pk))
          .then(() => {
            vm.$toasted.success(constant.SUCCESS.DISMISS);
            vm.$router.push({ name: "monitor" });
          })
          .catch((error) => {
            if (error.errors) {
              Object.keys(error.errors).map((key) => {
                vm.$toasted.error(error.errors[key]);
              });
            } else {
              vm.$toasted.error(constant.ERROR.UNEXCEPTED);
            }
          })
          .finally(() => {
            common.loaded();
          });
      }
    },
    getBannedReason() {
      if (this.reasonValue === 9) {
        return this.reason;
      } else {
        const reasonItem = this.reasonList.find(
          (i) => i.value === this.reasonValue
        );
        if (reasonItem) {
          return reasonItem.text;
        } else {
          return null;
        }
      }
    },
    calculateScreenSize() {
      const width = document.querySelector("div#liver_stream").offsetWidth;
      if (width && width > 0) {
        const height = width * (16 / 9);
        console.log(width, height);
        document.querySelectorAll("div.stream").forEach((ele) => {
          ele.style.minHeight = `${height}px`;
        });
        document.querySelectorAll("div.waiting-list").forEach((ele) => {
          ele.style.minHeight = `${height}px`;
        });
      }
    },
  },
};
</script>
<style scoped>
.reason-radios >>> .md-radio {
  width: 100%;
}
.reason-textarea {
  margin-left: 30px;
  width: 90%;
}
.monitor-detail .md-layout-item {
  text-align: center;
}
.monitor-detail .nickname {
  font-size: 18px;
  font-weight: bold;
  max-width: calc(100% - 60px);
  display: inline-block;
  overflow: hidden;
}
div.stream {
  /* min-height: 200px; */
  width: 95%;
  background-color: lightgray;
  position: relative;
}
div.stream img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 80px;
}
div.waiting-list {
  overflow-y: scroll;
  margin-top: 50px;
}
.dropdown-menu-body {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 10px;
}
/* .dropdown-menu-content-body {
  padding: 10px;
} */
.dropdown-menu-content-header {
  text-align: center;
  padding: 5px;
  color: rgba(224, 81, 147, 100);
  border-bottom: 1px solid #eee;
  font-weight: bold;
}
.dropdown-menu-content-item {
  text-align: left;
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
.dropdown-menu-content-item:hover {
  color: rgba(255, 255, 255, 100);
  background-color: rgba(224, 81, 147, 100);
}
.dropdown-menu-content-item:last-child {
  border-bottom: 0px;
}
.dropdown-menu-content-btn {
  opacity: 0.6;
  background-color: rgba(224, 81, 147, 100);
  border-radius: 24px;
  height: 24px;
  width: 24px;
  line-height: 24px;
  cursor: pointer;
}
.dropdown-menu-content-btn:hover {
  opacity: 1;
}
.dropdown-menu-icon {
  color: #fff !important;
  font-size: 16px !important;
}
</style>
<style>
.v-dropdown-menu__container {
  border-radius: 10px;
}
</style>
